// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble {
  margin-bottom: 0.75rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}
.bubble__corner {
  display: flex;
  align-items: flex-end;
  padding-right: 55px;
  justify-content: flex-end;
}
.bubble__corner--left {
  transform: rotateY(180deg);
}
.bubble__corner--right {
  transform: rotateX(0);
}
.bubble__title {
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.75rem;
  font-weight: 500;
  line-height: 1.3;
}`, "",{"version":3,"sources":["webpack://./src/components/Bubble/Bubble.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,yBAAA;AADJ;AAGI;EACE,0BAAA;AADN;AAII;EACE,qBAAA;AAFN;AAME;EACE,gBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import '../../scss/variables';\n\n.bubble {\n  margin-bottom: 0.75rem;\n  width: fit-content;\n  position: relative;\n  user-select: none;\n\n  &__corner {\n    display: flex;\n    align-items: flex-end;\n    padding-right: 55px;\n    justify-content: flex-end;\n\n    &--left {\n      transform: rotateY(180deg);\n    }\n\n    &--right {\n      transform: rotateX(0);\n    }\n  }\n\n  &__title {\n    padding: 0.75rem;\n    background-color: white;\n    border-radius: 0.75rem;\n    font-weight: 500;\n    line-height: 1.3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
