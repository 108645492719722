// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
@media only screen and (min-width: 1280px) {
  .header {
    position: static;
  }
}
.header img {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EAEA,aAAA;AAHF;AAKE;EAZF;IAaI,gBAAA;EAFF;AACF;AAIE;EACE,eAAA;AAFJ","sourcesContent":["@import '../../scss/variables';\n\n.header {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n\n  z-index: 9999;\n\n  @media only screen and (min-width: $desktop) {\n    position: static;\n  }\n\n  img {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
