import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Hero from "./pages/Hero/Hero";
import './scss/normalize.scss'
import './scss/general.scss'
import Results from "./pages/Results/Results";
import QuizPage from "./pages/QuizPage/QuizPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Hero/>}/>
                <Route path="/quiz" element={<QuizPage />}/>
                <Route path="*" element={<Hero/>}/>
                <Route path="/results" element={<Results/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
