import React from 'react'
import './ChipField.scss'

interface IChipField {
    children: React.ReactNode
    to?: string
}

const ChipField: React.FC<IChipField> = (props) => {

        const handleClick = () => {
            if (props.to) {
                window.open(props.to, "_blank")
            }
        }

    return (
        <div className="chipfield" onClick={handleClick}>
            {props.children}
        </div>
    )
}

export default ChipField