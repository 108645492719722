import React from "react";
import ChipField from "../ChipField/ChipField";
import "./Footer.scss";
import TelegramIcon from "../../assets/telegram-icon.svg";
import Alpha from "../../assets/footer-alfa.svg";
import Budu from "../../assets/footer-budu.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__links">
        <a href="/assets/soglasie.pdf">Пользовательское соглашение</a>
        <a href="/assets/politika.pdf">Политика конфиденциальности</a>
        <a href="/assets/rules.pdf">Правила акции</a>
      </div>
      <div className="footer__icons">
        <ChipField to={"https://t.me/alfastudents"}>
          <img src={TelegramIcon} alt="" />
          <img src={Alpha} alt="" />
        </ChipField>
        <ChipField to={"https://t.me/budujobs"}>
          <img src={TelegramIcon} alt="" />
          <img src={Budu} alt="" />
        </ChipField>
      </div>
    </footer>
  );
};

export default Footer;
