import React, { useState } from "react";
import styled from "styled-components";
import TelegramButton from "../../components/TelegramButton/TelegramButton";
import Button from "../Button/Button";

const Overlay = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 64, 84, 0.6);
  backdrop-filter: blur(8px);
  animation: fadein 0.5s;
  z-index: 999;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Modal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  width: 25rem;
  height: 18rem;
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  transition: all 0.5s ease;
  z-index: 1;
`;

const CancelButton = styled.button``;

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export function HeroModal({ setShowModal }: ModalProps) {

  return (
    <Overlay onClick={() => setShowModal(false)}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <p style={{ marginBottom: "12px", textAlign: "center" }}>
          Чтобы препод смог найти тебя в&nbsp;аудитории, авторизуйся на сайте
          через свой Telegram-аккаунт и&nbsp;приступай к испытанию!
        </p>
        <TelegramButton
          isResultPage={false}
        />
        <Button onClick={() => setShowModal(false)}>Закрыть</Button>
        {/*<CancelButton onClick={() => setShowModal(false)}>Закрыть</CancelButton>*/}
      </Modal>
    </Overlay>
  );
}

export function FinalModal({ setShowModal }: ModalProps) {

  return (
    <Overlay onClick={() => setShowModal(false)}>
      <Modal onClick={(e) => e.stopPropagation()} style={{"display": "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <p></p>
        <p style={{ marginBottom: "12px", textAlign: "center" }}>
        Ура, все условия розыгрыша выполнены! Жди итогов 5&nbsp;февраля в&nbsp;канале Alfa&nbsp;Students.
        </p>
    
        <Button onClick={() => setShowModal(false)}>Закрыть</Button>
       
      </Modal>
    </Overlay>
  );
}
