import React, {useEffect, useRef} from "react";
import "./Bubble.scss";
import ButtonCorner from "../../assets/bubble-corner.svg";

interface IBubble {
    cornerPosition: "left" | "right";
    title: string
    currentQuestion: number
}

const Bubble: React.FC<IBubble> = (props) => {

    const bubbleRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (bubbleRef.current) {
            bubbleRef.current.innerHTML = props.title
        }
    }, [bubbleRef, props.title])

    return (
        <div className="bubble">
            <div className={`bubble__corner bubble__corner--${props.cornerPosition}`}>
                <img src={ButtonCorner} alt=""/>
            </div>
            <div className={"bubble__title"}
                 ref={bubbleRef}
            ></div>
        </div>
    );
};

export default Bubble;
