// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/StyreneAWeb-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/StyreneAWeb-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/StyreneAWeb-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Styrene";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Styrene";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Styrene";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  font-family: Styrene;
}`, "",{"version":3,"sources":["webpack://./src/scss/general.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,4CAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAIA;EACE,sBAAA;EACA,4CAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAFF;AAKA;EACE,sBAAA;EACA,4CAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAHF;AAMA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AAJF;;AAOA;EACE,uBAAA;EACA,oBAAA;AAJF","sourcesContent":["@import './variables';\n\n@font-face {\n  font-family: \"Styrene\";\n  src: url(\"../assets/fonts/StyreneAWeb-Regular.ttf\");\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Styrene\";\n  src: url(\"../assets/fonts/StyreneAWeb-Medium.ttf\");\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Styrene\";\n  src: url(\"../assets/fonts/StyreneAWeb-Bold.ttf\");\n  font-weight: 600;\n  font-style: normal;\n  font-display: swap;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  background-color: black;\n  font-family: Styrene;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
