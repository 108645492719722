// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rule-card {
  background-color: #4E4E4E;
  padding: 1rem;
  border-radius: 0.75rem;
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  grid-gap: 1rem;
}
@media only screen and (min-width: 1280px) {
  .rule-card {
    grid-gap: 1.5rem;
  }
}
.rule-card span {
  font-weight: 500;
  line-height: 1.3;
  color: white;
}
.rule-card img {
  height: 76px;
  width: 76px;
}`, "",{"version":3,"sources":["webpack://./src/components/RuleCard/RuleCard.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EAEA,yBAAA;UAAA,iBAAA;EACA,cAAA;AAFF;AAIE;EATF;IAUI,gBCNE;EDKJ;AACF;AAGE;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;AAFJ","sourcesContent":["@import '../../scss/variables';\n\n.rule-card {\n  background-color: $grey;\n  padding: 1rem;\n  border-radius: $borderRadius;\n  display: flex;\n  //justify-content: space-between;\n  user-select: none;\n  grid-gap: 1rem;\n\n  @media only screen and (min-width: $desktop) {\n    grid-gap: $gap;\n  }\n\n  span {\n    font-weight: 500;\n    line-height: 1.3;\n    color: white;\n  }\n\n  img {\n    height: 76px;\n    width: 76px;\n  }\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
