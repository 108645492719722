import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    total: 0,
    currentMark: 0,
    isLoggedIn: false,
    isSubscribed: false
};

export const quizReducer = createSlice({
    name: "quiz",
    initialState,
    reducers: {
        setTotal(state, actions: PayloadAction<number>) {
            state.total = state.total + actions.payload;
        },
        setCurrentMark(state, actions: PayloadAction<number>) {
            state.currentMark = actions.payload
        },
        setIsLoggedIn(state, actions: PayloadAction<boolean>) {
            state.isLoggedIn = actions.payload
        },
        setIsSubscribed(state, actions: PayloadAction<boolean>) {
            state.isSubscribed = actions.payload
        },
    },
});

export const {
    setTotal,
    setCurrentMark,
    setIsLoggedIn,
    setIsSubscribed
} =
    quizReducer.actions;

export default quizReducer.reducer;
