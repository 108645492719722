import React from 'react'
import './RuleCard.scss'

interface IRuleCard {
    isReversed?: boolean
    children: React.ReactNode
}

const RuleCard: React.FC<IRuleCard> = (props) => {

    return (
        <div className="rule-card">
            {props.children}
        </div>
    )
}

export default RuleCard