// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  align-items: flex-start;
  grid-gap: 1rem;
  padding: 1rem;
}
@media only screen and (min-width: 1280px) {
  .footer {
    padding: 1.5rem 0;
    justify-content: space-between;
    flex-direction: row;
  }
}
.footer__links {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
}
@media only screen and (min-width: 1280px) {
  .footer__links {
    grid-gap: 0.5rem;
  }
}
.footer__links a {
  cursor: pointer;
  color: #B9B9B9;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.3;
}
.footer__icons {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
}
.footer .chipfield {
  background-color: #333333;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;AADF;AAGE;EATF;IAUI,iBAAA;IACA,8BAAA;IACA,mBAAA;EAAF;AACF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;AAEI;EALF;IAMI,gBAAA;EACJ;AACF;AAEI;EACE,eAAA;EACA,cC3BM;ED4BN,qBAAA;EACA,eAAA;EACA,gBAAA;AAAN;AAIE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,yBCvCO;EDwCP,eAAA;AAHJ","sourcesContent":["@import '../../scss/variables';\n\n.footer {\n  background-color: black;\n  display: flex;\n  align-items: center;\n  flex-direction: column-reverse;\n  align-items: flex-start;\n  grid-gap: 1rem;\n  padding: 1rem;\n\n  @media only screen and (min-width: $desktop) {\n    padding: 1.5rem 0;\n    justify-content: space-between;\n    flex-direction: row;\n  }\n\n  &__links {\n    display: flex;\n    flex-direction: column;\n    grid-gap: .75rem;\n\n    @media only screen and (min-width: $desktop) {\n      grid-gap: .5rem;\n\n    }\n\n    a {\n      cursor: pointer;\n      color: $lightGrey;\n      text-decoration: none;\n      font-size: 14px;\n      line-height: 1.3;\n    }\n  }\n\n  &__icons {\n    display: flex;\n    align-items: center;\n    grid-gap: .75rem;\n  }\n\n  .chipfield {\n    background-color: $darkGrey;\n    cursor: pointer;\n  }\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
