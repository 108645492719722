// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer {
  border: 1px solid white;
  padding: 8px 12px;
  display: grid;
  border-radius: 12px;
  background-color: rgba(137, 0, 172, 0.8);
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;
  align-items: center;
  color: white;
  font-size: 0.875rem;
  line-height: 1.3;
  cursor: pointer;
  transition: 180ms;
  -webkit-user-select: none;
          user-select: none;
}
@media only screen and (min-width: 1280px) {
  .answer:hover {
    background-color: rgba(69, 0, 86, 0.8);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Answer/Answer.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,+BAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE;IACE,sCAAA;EADJ;AACF","sourcesContent":["@import '../../scss/variables';\n\n.answer {\n  border: 1px solid white;\n  padding: 8px 12px;\n  display: grid;\n  border-radius: 12px;\n  background-color: rgba(137, 0, 172, 0.8);\n  grid-template-columns: 1fr auto;\n  grid-gap: 0.75rem;\n  align-items: center;\n  color: white;\n  font-size: 0.875rem;\n  line-height: 1.3;\n  cursor: pointer;\n  transition: 180ms;\n  user-select: none;\n\n  @media only screen and (min-width: $desktop) {\n    &:hover {\n      background-color: rgba(69, 0, 86, 0.8);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
