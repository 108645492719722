import React, { useEffect, useRef } from 'react'
import './Answer.scss'
import AnswerIcon from '../../assets/answer-icon.svg'

interface IAnswer {
    onClick: any
    title: string
}

const Answer: React.FC<IAnswer> = (props) => {
    const answerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (answerRef.current) {
            answerRef.current.innerHTML = props.title
        }
    }, [answerRef])

    return (
        <div className="answer" onClick={props.onClick}>
            <div className="answer__title" ref={answerRef}></div>
            <img src={AnswerIcon} alt=""/>
        </div>
    )
}

export default Answer