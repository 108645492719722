import React, {useEffect, useRef} from 'react'
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import Brain from '../../assets/brain.svg'
import './Scale.scss'

const Scale = () => {
    const {total} = useSelector((state: RootState) => state.quiz)
    console.log('total', total)

    const indicatorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (indicatorRef.current) {
            indicatorRef.current.style.height = `calc(5% * ${total})`
        }
    }, [total, indicatorRef])


    return (
        <div className="scale">
            <div className="scale__ruler">
                <div className="scale__indicator" ref={indicatorRef}></div>
            </div>
            <img src={Brain} alt="" className="scale__img"/>
        </div>
    )
}

export default Scale