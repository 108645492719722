// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scale {
  position: absolute;
  top: 1rem;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}
@media only screen and (min-width: 1280px) {
  .scale {
    top: 43px;
    left: 58px;
  }
}
.scale__ruler {
  height: 200px;
  width: 18px;
  background-color: white;
  border: 2px solid white;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.scale__indicator {
  width: 100%;
  background-color: #A220D7;
}
.scale__img {
  position: absolute;
  left: -13px;
  bottom: -20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Scale/Scale.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,uBAAA;EAAA,kBAAA;AADF;AAGE;EANF;IAOI,SAAA;IACA,UAAA;EAAF;AACF;AAEE;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;EACA,qBAAA;AAAJ;AAGE;EACE,WAAA;EACA,yBCnBI;ADkBR;AAIE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AAFJ","sourcesContent":["@import '../../scss/variables';\n\n.scale {\n  position: absolute;\n  top: 1rem;\n  right: 0;\n  width: fit-content;\n\n  @media only screen and (min-width: $desktop) {\n    top: 43px;\n    left: 58px;\n  }\n\n  &__ruler {\n    height: 200px;\n    width: 18px;\n    background-color: white;\n    border: 2px solid white;\n    border-radius: .75rem;\n    overflow: hidden;\n    display: flex;\n    align-items: flex-end;\n  }\n\n  &__indicator {\n    width: 100%;\n    background-color: $lilac;\n  }\n\n  &__img {\n    position: absolute;\n    left: -13px;\n    bottom: -20px;\n  }\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
