import React, { useEffect } from "react";
import TelegramLoginButton from "react-telegram-login";
import Button from "../../components/Button/Button";
import { RootState } from "../../store";
import {useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setIsSubscribed } from "../../reducers/quizReducer";

const WEBHOOK_URL =
  "https://n8n.mybrunch.ru/webhook/ea81207b-deae-4a0e-93b5-4333ea7b871e";

interface ITelegramButton {
  isResultPage?: boolean;
  setIsFinalModal?: any
}

const TelegramButton: React.FC<ITelegramButton> = (props) => {
  const {
    isResultPage,
    setIsFinalModal
  } = props;

  const { isSubscribed} = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()

  useEffect(() => {
    checkAuth();
  }, []);

  const handleTelegramResponse = (response: any) => {
    console.log(response);
    sessionStorage.setItem("user_id", response.id);
    if (isResultPage) {
      checkAuth();
    }
    window.location.reload();
  };

  const checkAuth = () => {
    const userId = sessionStorage.getItem("user_id");
    dispatch(setIsLoggedIn(!!userId));
    if (userId && isResultPage) {
      checkSubscription();
    }
  };

  const checkSubscription = async () => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const response = await fetch(`${WEBHOOK_URL}?id=${userId}`, {
        method: "POST",
      });
      const result = await response.json();
      console.log("Проверка подписки на канал:", result);
      dispatch(setIsSubscribed(!!result.is_subscribed));
    } catch (error) {
      console.error("Ошибка сети. Попробуйте перезагрузить страницу.", error);
    }
  };

  return (
    <div className="TelegramLogin">
      <header className="TelegramLogin-header">
        {!isResultPage && (
          <TelegramLoginButton
            dataOnauth={handleTelegramResponse}
            botName="AlfaStudents_bot"
            lang="ru"
          />
        )}

        {/* {!isLoggedIn && (
          <p>
            <button onClick={checkSubscription}>Проверить подписку</button>
          </p>
        )} */}

        {isResultPage ? (
          isSubscribed ? (
            <Button onClick={() => {
              setIsFinalModal(true)
            }}>Спасибо за подписку</Button>
          ) : (
            <Button
              onClick={() => {
                window.open("https://t.me/alfastudents");
              }}
            >
              Подписаться
            </Button>
          )
        ) : (
          ""
        )}
      </header>
    </div>
  );
};

export default TelegramButton;
