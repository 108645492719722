import { useEffect, useRef } from "react";
import Typed from "typed.js";
import "./TypingBubble.scss";
import ButtonCorner from "../../assets/bubble-corner.svg";

interface IBubble {
  cornerPosition: "left" | "right";
}

const TypingBubble: React.FC<IBubble> = (props) => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Выйди и зайди нормально",
        "Ну просто лес рук",
        "Автоматов не будет",
        "Здесь вам не школа",
        "Жду вас на отработках",
        "Расскажи всем, мы тоже посмеёмся",
        "Вы — моя худшая группа",
        "Галёрка, как слышно?",
      ],
      typeSpeed: 70,
      loop: true,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="typing_bubble">
      <div
        className={`typing_bubble__corner typing_bubble__corner--${props.cornerPosition}`}
      >
        <img src={ButtonCorner} alt="" />
      </div>
      <span className={"typing_bubble__title"} ref={el} />
    </div>
  );
};

export default TypingBubble;
