import React from 'react'
import Logo from '../../assets/logo_desktop.svg'
import './Header.scss'
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate()

    return (
        <header className={"header"}>
            <img src={Logo} alt="" onClick={() => {
                navigate("/")
            }}/>
        </header>
    )
}

export default Header