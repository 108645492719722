import React, {SetStateAction, useEffect, useRef, useState} from 'react'
import './Quiz.scss'
import Reaction from "../Reaction/Reaction";
import Bubble from "../Bubble/Bubble";
import Button from "../Button/Button";
import Counter from "../Counter/Counter";
import Answer from "../Answer/Answer";
import quiz from '../../data/quiz.json'
import {useDispatch, useSelector} from "react-redux";
import {setCurrentMark, setTotal} from "../../reducers/quizReducer";
import {useNavigate} from "react-router-dom";

interface IQuiz {
    setIsTeachersBubble: React.Dispatch<SetStateAction<boolean>>
    isTeachersBubble: boolean
}

const Quiz: React.FC<IQuiz> = (props) => {
    const {setIsTeachersBubble, isTeachersBubble} = props
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    
    const [index, setIndex] = useState<number>(0)

    const [reactionIndex, setReactionIndex] = useState<number>(0)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const reaction = [
        "Хорошо идёте, студент",
        "+1 балл доверия",
        "Ну-ка соберитесь!",
        "Больше выдумки, студент!",
        "Вы точно готовились?",
        "-1 балл доверия"
    ]

    return <div className="quiz">
        <div className="quiz__bubble-container">
            <Bubble cornerPosition="left" title={
                isTeachersBubble ?
                    quiz[currentQuestion].answers[index].teacher
                    :
                    quiz[currentQuestion].question
            }
            currentQuestion={currentQuestion}
            />
            <Counter current={currentQuestion + 1} total={quiz.length}/>
        </div>
        {isTeachersBubble
            ?
            <>
                <Reaction>{reaction[reactionIndex - 1]}</Reaction>
                <Button
                    onClick={() => {
                        if (currentQuestion === quiz.length - 1) {
                            navigate('/results')
                        } else {
                            dispatch(setCurrentMark(1))
                            setIsTeachersBubble((prev) => !prev)
                            setCurrentQuestion((prev) => prev + 1)
                        }
                    }}
                >
                    Далее
                </Button>
            </>
            :
            <div className="quiz__answers">
                {quiz[currentQuestion].answers.map((answer: any, index: number) => {
                    return (
                        <Answer
                            key={index}
                            onClick={() => {
                                setIsTeachersBubble((prev) => !prev)
                                setIndex(index)
                                setReactionIndex(answer.index)
                                dispatch(setCurrentMark(answer.mark))
                                dispatch(setTotal(answer.mark))
                            }}
                            title={answer.student}
                        />
                    )
                })}
            </div>
        }
    </div>
}

export default Quiz