// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  min-height: calc(100dvh - 66px);
}

.layout {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  .layout {
    padding: 0 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/Layout/Layout.scss"],"names":[],"mappings":"AAEA;EACE,+BAAA;AADF;;AAIA;EACE,cAAA;EACA,iBAAA;EACA,WAAA;AADF;AAGE;EALF;IAMI,eAAA;EAAF;AACF","sourcesContent":["@import '../../scss/variables';\n\nmain {\n  min-height: calc(100dvh - $headerHeightDesktop);\n}\n\n.layout {\n  margin: 0 auto;\n  max-width: 1280px;\n  width: 100%;\n\n  @media only screen and (min-width: $desktop) {\n    padding: 0 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
