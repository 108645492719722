import React, { useState, useEffect } from "react";
import "./Results.scss";
import Layout from "../../hoc/Layout/Layout";
import ChipField from "../../components/ChipField/ChipField";
import RuleCard from "../../components/RuleCard/RuleCard";
import HeroAlphaTelegram from "../../assets/result-alpha-telegram.svg";
import ResultsBackgroundDesktop from "../../assets/results-background-desktop.svg";
import ResultsBackgroundMobile from "../../assets/results-background-mobile.svg";
import HeroCalendar from "../../assets/hero-calendar.svg";
import Button from "../../components/Button/Button";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import FiveDesktop from "../../assets/results-five.svg";
import FiveMobile from "../../assets/results-five-mobile.svg";
import FourDesktop from "../../assets/results-four.svg";
import FourMobile from "../../assets/results-four-mobile.svg";
import ThreeDesktop from "../../assets/results-three.svg";
import ThreeMobile from "../../assets/results-three-mobile.svg";
import TwoDesktop from "../../assets/results-two.svg";
import TwoMobile from "../../assets/results-two-mobile.svg";
import TelegramButton from "../../components/TelegramButton/TelegramButton";
import {useNavigate} from 'react-router-dom'
import {FinalModal} from '../../components/Modal/Modal'

const Results = () => {
  const { total, isLoggedIn } = useSelector((state: RootState) => state.quiz);
  const navigate = useNavigate()
  const [isFinalModal, setIsFinalModal] = useState<boolean>(true)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn])

  const countMark = (total: number) => {
    if (total > 15) {
      return "A";
    }
    if (total > 10) {
      return "B";
    }
    if (total > 5) {
      return "C";
    }
    return "D";
  };

  const resultText = {
    A: {
      title: "О, у вас пятёрка, браво!",
      description: (
        <p>
          Вот бы так и в жизни всё было, да? Жаль, что это просто было сном…
          Но&nbsp;даже в&nbsp;нём тебе удалось доказать,
          что&nbsp;с&nbsp;находчивостью и&nbsp;чувством юмора у&nbsp;тебя всё
          супер 👏
        </p>
      ),
      mark: (
        <picture>
          <source media="(min-width:1280px)" srcSet={FiveDesktop} />
          <img src={FiveMobile} alt="" />
        </picture>
      ),
    },
    B: {
      title: "Ммм, четвёрка, это хорошо!",
      description: (
        <p>
          В&nbsp;искусстве находчивости и&nbsp;эрудиции ты явно в&nbsp;топе,
          поздравляем! Жалко, конечно, что&nbsp;это было просто сном…
          Но&nbsp;уверены, что в&nbsp;жизни ты&nbsp;тоже всегда на&nbsp;коне 👍
        </p>
      ),
      mark: (
        <picture>
          <source media="(min-width:1280px)" srcSet={FourDesktop} />
          <img src={FourMobile} alt="" />
        </picture>
      ),
    },
    C: {
      title: "Тройка.. Неплохо, но вы могли лучше",
      description: (
        <p>
          Ты умело балансируешь между знаниями и&nbsp;искусством импровизации
          на&nbsp;экзамене. Жаль, конечно, что&nbsp;это было только сном… Пусть
          в&nbsp;жизни будет всё ещё мощнее 🎉
        </p>
      ),
      mark: (
        <picture>
          <source media="(min-width:1280px)" srcSet={ThreeDesktop} />
          <img src={ThreeMobile} alt="" />
        </picture>
      ),
    },
    D: {
      title: "Эх, двойка… Ждём вас на пересдаче!",
      description: (
        <p>
          Как хорошо, что это было просто сном. Главное, что&nbsp;у&nbsp;тебя
          получилось весело провести время с&nbsp;нашим бесом пересдач
          и&nbsp;проверить свою интуицию 🙂
        </p>
      ),
      mark: (
        <picture>
          <source media="(min-width:1280px)" srcSet={TwoDesktop} />
          <img src={TwoMobile} alt="" />
        </picture>
      ),
    },
  };

  return (
    <Layout>
      <section className="results">
        <div className="results__description">
          {resultText[countMark(total)].mark}
          <div>
            <h1>{resultText[countMark(total)].title}</h1>
            {resultText[countMark(total)].description}
            <p>
              Больше всего на свете мы ценим умных и&nbsp;свободных,
              и&nbsp;желаем, чтобы в&nbsp;студенческой жизни эти качества
              обязательно тебе пригодились. Помни, что&nbsp;бы&nbsp;ни
              случилось, тебе под&nbsp;силу любые сложности,&nbsp;побед будет
              только больше!
            </p>
          </div>
        </div>
        <div className="results__gifts">
          <div className="results__advice">
            И в благодарность за находчивость, хотим наградить тебя. Участвуй
            в&nbsp;конкурсе от&nbsp;Alfa Students и&nbsp;получи шанс выиграть
            один из&nbsp;классных призов:
          </div>
          <div className="results__chipfields">
            <ChipField>смарт-часы Apple Watch</ChipField>
            <ChipField>бомбер</ChipField>
            <ChipField>футболка</ChipField>
            <ChipField>рюкзак</ChipField>
            <ChipField>кроксы</ChipField>
          </div>
          <div className="results__rule-cards">
            <RuleCard>
              <img src={HeroAlphaTelegram} alt="" />
              <span>
                Для этого нужно только подписаться на&nbsp;Телеграм-канал Alfa
                Students.
              </span>
            </RuleCard>
            <RuleCard>
              <img src={HeroCalendar} alt="" />
              <span>
                И ждать результатов розыгрыша, которые будут опубликованы
                в&nbsp;нём 5&nbsp;февраля.
              </span>
            </RuleCard>
          </div>
          <div className="results__actions">
            <TelegramButton
              isResultPage={true}
              setIsFinalModal={setIsFinalModal}
              
            />
          </div>
        </div>
      </section>
      {isFinalModal ? <FinalModal setShowModal={setIsFinalModal}/> : null}
    </Layout>
  );
};

export default Results;
