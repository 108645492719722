import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import "./Hero.scss";
import ButtonLeftIcon from "../../assets/button-left-icon.svg";
import ButtonRightIcon from "../../assets/button-right-icon.svg";
import RuleCard from "../../components/RuleCard/RuleCard";
import Layout from "../../hoc/Layout/Layout";
import HeroPeace from "../../assets/hero-peace.svg";
import HeroClock from "../../assets/hero-clock.svg";
import MediaQuery from "react-responsive";
import HeroTeacherDesktop from "../../assets/hero-teacher-desktop.svg";
import HeroTeacherMobile from "../../assets/hero-teacher-mobile.svg";
import HeroDesktopElements from "../../assets/hero-desktop-elements.svg";
import HeroMobileElements from "../../assets/hero-mobile-elements.svg";
import { useNavigate } from "react-router-dom";
import TelegramButton from "../../components/TelegramButton/TelegramButton";
import TypingBubble from "../../components/TypingBubble/TypingBubble";
import ym from "react-yandex-metrika";
import { HeroModal } from "../../components/Modal/Modal";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Hero = () => {
  const { isLoggedIn, isSubscribed } = useSelector(
    (state: RootState) => state.quiz
  );
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/quiz");
    }
  }, [isLoggedIn]);

  const ButtonHandShake = () => {
    return (
      <Button
        onClick={() => {
          //yandex-metrika
          ym("hit", "/quiz");
          ym("reachGoal", "cta1");
          setShowModal(!showModal);
        }}
      >
        <img
          src={ButtonLeftIcon}
          alt=""
          className={"button__icon button__icon--left"}
        />
        <img
          src={ButtonRightIcon}
          alt=""
          className={"button__icon button__icon--right"}
        />
        <span>Начать</span>
      </Button>
    );
  };

  return (
    <Layout>
      <section className={"hero"}>
        <div className="hero__main">
          <div className="hero__content">
            <div className="hero__description">
              <div className="hero__h1">
                Внимание, вас посетил бес&nbsp;пересдач! Он принёс игру
                на&nbsp;находчивость, в&nbsp;которой нужно сдать экзамен
                без&nbsp;подготовки
              </div>
              <h2 className="hero__h2">
                Студенчество — время новых открытий и&nbsp;знаний, когда любой
                экзамен становится испытанием не&nbsp;только эрудиции,
                но&nbsp;и&nbsp;удачи, а&nbsp;также способности уверенно говорить
                обо&nbsp;всём на&nbsp;свете.
              </h2>
            </div>
            <div className="hero__actions">
              <MediaQuery minWidth={1280}>
                <ButtonHandShake />
              </MediaQuery>
            </div>
          </div>
          <picture className={"hero__elements"}>
            <source media="(min-width:1280px)" srcSet={HeroDesktopElements} />
            <img src={HeroMobileElements} alt="" />
          </picture>
          <div className="hero__image">
            <picture>
              <source media="(min-width:1280px)" srcSet={HeroTeacherDesktop} />
              <img src={HeroTeacherMobile} alt="" />
            </picture>
            <TypingBubble cornerPosition="right" />
          </div>
        </div>
        <div className="hero__rule-cards">
          <RuleCard>
            <span>
              На часах 9:00, экзамен начинается… Твоя задача — заболтать
              препода.
            </span>
            <img src={HeroClock} alt="" />
          </RuleCard>
          <RuleCard>
            <span>
              Если всё пройдёт идеально, то&nbsp;вся группа получит пятерки
              автоматом, а&nbsp;ты&nbsp;станешь героем универа и&nbsp;сможешь
              выиграть классные подарки от&nbsp;Alfa Students!
            </span>
            <img src={HeroPeace} alt="" />
          </RuleCard>
          <MediaQuery maxWidth={1280}>
            <ButtonHandShake />
          </MediaQuery>
        </div>
      </section>
      {showModal && <HeroModal setShowModal={setShowModal} />}
    </Layout>
  );
};

export default Hero;
