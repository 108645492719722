// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chipfield {
  padding: 0.5rem 1rem;
  background-color: #A220D7;
  line-height: 1.3;
  font-weight: 500;
  color: white;
  border-radius: 2rem;
  grid-gap: 0.5rem;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  font-size: 1rem;
}
@media only screen and (min-width: 1280px) {
  .chipfield {
    font-size: 1.25rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ChipField/ChipField.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,yBCGM;EDFN,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AADF;AAGE;EAbF;IAcI,kBAAA;EAAF;AACF","sourcesContent":["@import '../../scss/variables';\n\n.chipfield {\n  padding: .5rem 1rem;\n  background-color: $lilac;\n  line-height: 1.3;\n  font-weight: 500;\n  color: white;\n  border-radius: 2rem;\n  grid-gap: .5rem;\n  display: flex;\n  align-items: center;\n  user-select: none;\n  font-size: 1rem;\n\n  @media only screen and (min-width: $desktop) {\n    font-size: 1.25rem;\n  }\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
