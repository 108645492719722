// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing_bubble {
  margin-bottom: 0.75rem;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-user-select: none;
          user-select: none;
  top: 35%;
  left: 10%;
  position: absolute;
}
.typing_bubble__corner {
  display: flex;
  align-items: flex-end;
  padding-right: 15px;
  justify-content: flex-start;
  margin-left: 20px;
}
.typing_bubble__corner--left {
  transform: rotateY(180deg);
}
.typing_bubble__corner--right {
  transform: rotateX(0);
}
.typing_bubble__title {
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.75rem;
  font-weight: 500;
  line-height: 1.3;
  display: block;
  height: 45px;
}

@media only screen and (min-width: 1280px) {
  .typing_bubble {
    top: 35%;
    left: 25%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TypingBubble/TypingBubble.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,2BAAA;EACA,iBAAA;AADJ;AAGI;EACE,0BAAA;AADN;AAII;EACE,qBAAA;AAFN;AAME;EACE,gBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AAJJ;;AAQA;EACE;IACE,QAAA;IACA,SAAA;EALF;AACF","sourcesContent":["@import \"../../scss/variables\";\n\n.typing_bubble {\n  margin-bottom: 0.75rem;\n  width: fit-content;\n  user-select: none;\n  top: 35%;\n  left: 10%;\n  position: absolute;\n\n  &__corner {\n    display: flex;\n    align-items: flex-end;\n    padding-right: 15px;\n    justify-content: flex-start;\n    margin-left: 20px;\n\n    &--left {\n      transform: rotateY(180deg);\n    }\n\n    &--right {\n      transform: rotateX(0);\n    }\n  }\n\n  &__title {\n    padding: 0.75rem;\n    background-color: white;\n    border-radius: 0.75rem;\n    font-weight: 500;\n    line-height: 1.3;\n    display: block;\n    height: 45px;\n  }\n}\n\n@media only screen and (min-width: $desktop) {\n  .typing_bubble {\n    top: 35%;\n    left: 25%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
