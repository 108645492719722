import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Layout.scss";
import { YMInitializer } from "react-yandex-metrika";

interface ILayout {
  children: React.ReactNode;
  isFooterHide?: boolean;
}

const Layout: React.FC<ILayout> = (props) => {
  const { children, isFooterHide = false } = props;

  return (
    <div className={"layout"}>
      <YMInitializer accounts={[96202027]} options={{ webvisor: true }} />

      <Header />
      <main>{children}</main>
      {isFooterHide ? null : <Footer />}
    </div>
  );
};
export default Layout;
