import React from 'react'
import './Counter.scss'

interface ICounter {
    current: number
    total: number
}
const Counter: React.FC<ICounter> = (props) => {
    return (
        <div className="counter">
            Препод ({props.current}/{props.total})
        </div>
    )
}

export default Counter