import React, {JSX, useEffect, useState} from 'react'
import Layout from "../../hoc/Layout/Layout";
import Quiz from "../../components/Quiz/Quiz";
import './QuizPage.scss'

import TeacherDesktopDefault from '../../assets/teacher-desktop-default.svg'
import TeacherDesktopNeutral from '../../assets/teacher-desktop-neutral.svg'
import TeacherDesktopFalse from '../../assets/teacher-desktop-false.svg'
import TeacherDesktopTrue from '../../assets/teacher-desktop-true.svg'

import TeacherMobileDefault from '../../assets/teacher-mobile-default.svg'
import TeacherMobileNeutral from '../../assets/teacher-mobile-neutral.svg'
import TeacherMobileFalse from '../../assets/teacher-mobile-false.svg'
import TeacherMobileTrue from '../../assets/teacher-mobile-true.svg'

import {useSelector} from "react-redux";
import {RootState} from "../../store";
import Scale from "../../components/Scale/Scale";
import {useNavigate} from "react-router-dom";

const QuizPage = () => {
    const {currentMark, isLoggedIn} = useSelector((state: RootState) => state.quiz)
    const [isTeachersBubble, setIsTeachersBubble] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/')
        }
    }, [isLoggedIn])


    const teacher: Record<string, JSX.Element> = {
        "0":
            <picture>
                <img src={TeacherDesktopFalse} alt=""/>
            </picture>,
        "1":
            <picture>
                <img src={TeacherDesktopNeutral} alt=""/>
            </picture>,
        "2":
            <picture>
                <img src={TeacherDesktopTrue} alt=""/>
            </picture>
    }

    return (
        <Layout isFooterHide={true}>
            <section className="quiz-page">
                <Scale/>
                {
                    isTeachersBubble ?

                        teacher[currentMark]
                        :
                        <picture>
                            <img src={TeacherDesktopDefault} alt=""/>
                        </picture>
                }
                <Quiz
                    isTeachersBubble={isTeachersBubble}
                    setIsTeachersBubble={setIsTeachersBubble}
                />
            </section>
        </Layout>
    )
}

export default QuizPage