// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz__answers {
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
}
.quiz .bubble {
  width: 100%;
}
.quiz .bubble__title {
  border-top-right-radius: 0;
}
.quiz__bubble-container {
  position: relative;
  align-self: flex-start;
}
.quiz__bubble-container .counter {
  position: absolute;
  top: 0;
  right: 0;
}
.quiz .button {
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  .quiz {
    width: 400px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Quiz/Quiz.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAHJ;AAME;EACE,WAAA;AAJJ;AAMI;EACE,0BAAA;AAJN;AAQE;EACE,kBAAA;EACA,sBAAA;AANJ;AAQI;EACE,kBAAA;EACA,MAAA;EACA,QAAA;AANN;AAUE;EACE,WAAA;AARJ;AAWE;EA/BF;IAgCI,YAAA;EARF;AACF","sourcesContent":["@import '../../scss/variables';\n\n.quiz {\n\n  &__answers {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 1.25rem;\n  }\n\n  .bubble {\n    width: 100%;\n\n    &__title {\n      border-top-right-radius: 0;\n    }\n  }\n\n  &__bubble-container {\n    position: relative;\n    align-self: flex-start;\n\n    .counter {\n      position: absolute;\n      top: 0;\n      right: 0;\n    }\n  }\n\n  .button {\n    width: 100%;\n  }\n\n  @media only screen and (min-width: $desktop) {\n    width: 400px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
