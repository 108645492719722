import React from 'react'
import './Button.scss'


interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement>  {}

const Button: React.FC<IButton> = (props) => {
    return (
        <button
            className={`button ${props.className}`}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default Button