// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reaction {
  border: 2px solid #A2FC4E;
  border-radius: 0.75rem;
  display: flex;
  grid-gap: 0.75rem;
  align-items: center;
  background-color: white;
  font-weight: 500;
  line-height: 1.3;
  padding: 8px 12px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 63px;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Reaction/Reaction.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;AADF","sourcesContent":["@import '../../scss/variables';\n\n.reaction {\n  border: 2px solid $buttonDefault;\n  border-radius: 0.75rem;\n  display: flex;\n  grid-gap: 0.75rem;\n  align-items: center;\n  background-color: white;\n  font-weight: 500;\n  line-height: 1.3;\n  padding: 8px 12px;\n  width: fit-content;\n  margin-bottom: 63px;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
