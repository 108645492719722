// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  background: none;
  border: none;
  padding: 1rem 4rem;
  font-weight: bold;
  border-radius: 0.75rem;
  border: 1px solid white;
  cursor: pointer;
  background: #A2FC4E;
  width: -moz-fit-content;
  width: fit-content;
}
.button--small {
  font-size: 1rem;
  line-height: 1.3;
}
.button--big {
  font-size: 1.25rem;
  line-height: 1.4;
}
@media only screen and (min-width: 1280px) {
  .button:hover {
    transition: box-shadow 180ms ease-in-out;
    box-shadow: 0px 0px 30px 0px #A2FC4E;
  }
}
.button:active {
  background-color: #6BC210;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EAEE,gBAAA;EACA,YAAA;EAEA,kBAAA;EACA,iBAAA;EACA,sBCJa;EDKb,uBAAA;EACA,eAAA;EACA,mBCZc;EDad,uBAAA;EAAA,kBAAA;AAHF;AAKE;EACE,eAAA;EACA,gBAAA;AAHJ;AAME;EACE,kBAAA;EACA,gBAAA;AAJJ;AAOE;EACE;IACE,wCAAA;IACA,oCAAA;EALJ;AACF;AAQE;EACE,yBChCU;AD0Bd","sourcesContent":["@import '../../scss/variables';\n\n.button {\n  // reset default properties\n  background: none;\n  border: none;\n\n  padding: 1rem 4rem;\n  font-weight: bold;\n  border-radius: $borderRadius;\n  border: 1px solid white;\n  cursor: pointer;\n  background: $buttonDefault;\n  width: fit-content;\n\n  &--small {\n    font-size: 1rem;\n    line-height: 1.3;\n  }\n\n  &--big {\n    font-size: 1.25rem;\n    line-height: 1.4;\n  }\n\n  @media only screen and (min-width: $desktop) {\n    &:hover {\n      transition: box-shadow 180ms ease-in-out;\n      box-shadow: 0px 0px 30px 0px #A2FC4E;\n    }\n  }\n\n  &:active {\n    background-color: $buttonFocus;\n  }\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
