import React from 'react'
import './Reaction.scss'
import ReactionIcon from '../../assets/reaction-icon.svg'

interface IReaction {
    children: React.ReactNode
}

const Reaction: React.FC<IReaction> = (props) => {

    return (
        <div className="reaction">
            <img src={ReactionIcon} alt=""/>
            <span>{props.children}</span>
        </div>
    )
}

export default Reaction