// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A2FC4E;
  padding: 6px 12px;
  font-weight: 500;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border: 1px solid white;
  height: 39px;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Counter/Counter.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBCNc;EDOd,iBAAA;EACA,gBAAA;EACA,+BAAA;EACA,gCAAA;EACA,uBAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;AADF","sourcesContent":["@import '../../scss/variables';\n\n.counter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: $buttonDefault;\n  padding: 6px 12px;\n  font-weight: 500;\n  border-top-left-radius: 0.75rem;\n  border-top-right-radius: 0.75rem;\n  border: 1px solid white;\n  height: 39px;\n  user-select: none;\n}","$buttonDefault: #A2FC4E;\n$buttonFocus: #6BC210;\n$lightGrey: #B9B9B9;\n$grey: #4E4E4E;\n$darkGrey: #333333;\n$borderRadius: 0.75rem;\n$gap: 1.5rem;\n$lilac: #A220D7;\n\n\n$desktop: 1280px;\n$headerHeightMobile: 66px;\n$headerHeightDesktop: 66px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
